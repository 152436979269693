import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from 'framer-motion';
import { Box, useBreakpointValue } from "@chakra-ui/react";

const Home = React.lazy(() => import("../Pages/Home"));
const Me = React.lazy(() => import("../Pages/Me"));
const Booking = React.lazy(() => import("../Pages/Booking"));
const Flash = React.lazy(() => import("../Pages/Flash"));
const Aftercare = React.lazy(() => import("../Pages/Aftercare"));
const Projects = React.lazy(() => import("../Pages/Projects"));
const Contact = React.lazy(() => import("../Pages/Contact"));
const NotFound = React.lazy(() => import("../Pages/NotFound"));
const Questions = React.lazy(() => import("../Pages/Questions"));
const Review = React.lazy(() => import("../Pages/Review"));

const animationTime = `2s`;

const RouterRoutes = () => {
  const location = useLocation();
  // const baseUrl = process.env.REACT_APP_DOMAIN_URL || "localhost:3000";
  const mobileOrDesktop = useBreakpointValue({ base: 'mobile', lg: 'desktop' });

  const inputs = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  return (
    <Suspense fallback={<></>}>
      <Box p={0}>
        {mobileOrDesktop === 'mobile' ? (
          <Box></Box>
        ) : (
          <Box position="fixed" zIndex="1000" left="1.9%">
            <Box
              position="absolute"
              w="4px"
              transition={`height ${animationTime}`}
              bg="divider"
              left="1.9%"
              zIndex="1000"
              opacity="80%"
            ></Box>
          </Box>
        )}
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Home />
                </motion.div>
              }
            />
            <Route
              path="/me"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Me />
                </motion.div>
              }
            />
            <Route
              path="/booking"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Booking />
                </motion.div>
              }
            />
            <Route
              path="/flash"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Flash />
                </motion.div>
              }
            />
            <Route
              path="/aftercare"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Aftercare />
                </motion.div>
              }
            />
            <Route
              path="/projects"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Projects />
                </motion.div>
              }
            />
            <Route
              path="/contact"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Contact />
                </motion.div>
              }
            />
            <Route
              path="/review"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Review />
                </motion.div>
              }
            />
            <Route
              path="/faq"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <Questions />
                </motion.div>
              }
            />
            <Route
              path="*"
              element={
                <motion.div variants={inputs} animate="animate" initial="initial">
                  <NotFound />
                </motion.div>
              }
            />
          </Routes>
        </AnimatePresence>
      </Box>
    </Suspense>
  );
};

export default RouterRoutes;