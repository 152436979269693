import React from 'react';
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from "./Hooks/scrollToTop";
import customTheme from "./Theme";
import Fonts from './Theme/fonts';
import AppRoutes from "./RouterRoutes";

const App = () => (
  <React.StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={customTheme} resetCSS={true}>
      <Fonts />
      <BrowserRouter>
        <ScrollToTop />
        <AppRoutes />
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

export default App;